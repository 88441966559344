import React from 'react';
import { Link } from 'gatsby';
import Container from '../components/Container';

const NotFoundPage = () => (
  <Container>
    <h1>Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist.</p>
    <Link to="/">Go back to the homepage</Link>
  </Container>
);

export default NotFoundPage;
